<template>
  <div class="uploader-container p-6 bg-white rounded-lg shadow-md border border-gray-200">
    <label for="file-upload"
      class="file-upload-label block w-full text-center py-3 px-4 border-2 border-dashed border-gray-300 rounded-md hover:border-gray-400 transition-colors cursor-pointer">
      <input id="file-upload" ref="fileRef" type="file" multiple accept=".pdf,.jpg,.jpeg,.png" @change="onChange"
        class="hidden" />
      Click to select file(s)
    </label>
    <div v-if="sending" class="mt-4 text-center">
      <TgLoader />
      <div class="mt-2 text-gray-600">
        Uploading {{ complete }} of {{ total }} files...
      </div>
    </div>
    <div v-else-if="previewFiles.length" class="preview-area mt-4">
      <div class="text-gray-700 font-semibold mb-2">Files to upload:</div>
      <ul class="space-y-1">
        <li v-for="(file, index) in previewFiles" :key="index" class="preview-item p-2 border rounded-md">
          {{ file.name }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import TgLoader from '@/components/Common/TgLoader.vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  folder: String,
  filename: String,
})
const emit = defineEmits(['upload:success'])

const route = useRoute()
const clientKey = computed(() => route.params.client)

const fileRef = ref(null)
const previewFiles = ref([])
const complete = ref(0)
const total = ref(0)
const sending = ref(false)

function onChange(event) {
  const files = event.target.files
  if (files.length) {
    const fileArray = Array.from(files)
    previewFiles.value = fileArray
    processFiles(fileArray)
  }
}

function processFiles(files) {
  total.value = files.length
  complete.value = 0
  sending.value = true

  const jobs = files.map(file => upload(file))
  Promise.all(jobs)
    .then(resp => {
      sending.value = false
      emit('upload:success', resp)
      // Clear file input and preview files after successful upload
      if (fileRef.value) fileRef.value.value = ''
      previewFiles.value = []
    })
    .catch(err => {
      console.error('Error during upload:', err)
      sending.value = false
    })
}

async function upload(file) {
  const CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/tplive/upload"
  const CLOUDINARY_UPLOAD_PRESET = "vue_wvky6qwr"

  const formData = new FormData()
  formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET)
  formData.append("folder", `${clientKey.value}/${props.folder}`)
  formData.append("file", file)
  formData.append("public_id", props.filename || file.name.split(".")[0])

  try {
    const response = await fetch(CLOUDINARY_URL, {
      method: "POST",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
      body: formData,
    })
    const data = await response.json()
    if (response.ok) {
      complete.value++
      return data
    } else {
      const errorMsg = data.error?.message || "Upload failed"
      throw new Error(errorMsg)
    }
  } catch (error) {
    console.error("Upload error:", error)
    throw error
  }
}
</script>

<style scoped>
.uploader-container {
  max-width: 500px;
  margin: 0 auto;
}

.file-upload-label {
  font-size: 1rem;
  color: #4a5568;
}

.preview-area {
  font-size: 0.9rem;
}

.preview-item {
  background-color: #f7fafc;
}
</style>