export const PAGECONFIG = {
  select: [],
  values: [],
  groupby: [],
  orderby: [],
  filters: [],
  pivot_arr: [],
  form: [],
  grid_fields: [],
  form_fields: [],
  slots: {},
  tabs: []
}

export const DASH_DEFAULT = {
  filters: "{}",
  layout: [],
  slug: ""
}

export const GRAVATARS = {
  "virginaustralia.com": {
    "css": "bg-red-500 text-white",
    "text": "VA"
  },
  "iberia.com": {
    "css": "bg-red-500 text-white",
    "text": "IB"
  },
  "iberia.com": {
    "css": "bg-red-500 text-white",
    "text": "VI"
  },
  "qantas.com": {
    "css": "bg-red-500 text-white",
    "text": "QF"
  },
  "touchpointglobal.com": {
    "css": "bg-primary-500 text-white",
    "text": "TP"
  },
}

export const EXCLUDED_FIELDS = [
  "rowhash", "_changets", "_changeuser", "_pushdate", "_changetype"]

export const MENU_CONDITION_DEFAULTS_V2 =
{
  "menuitem": { "type": "text", "show": 1 },
  "app": { "type": "text", "show": 1 },
  "airline": { "type": "text", "show": 1 },
  "segmentkey": { "type": "text", "show": 1 },
  "departureport": { "type": "text", "show": 1 },
  "arrivalport": { "type": "text", "show": 1 },
  "direction": { "type": "text", "show": 1 },
  "changefrequency": { "type": "text", "show": 1 },
  "menustage": { "type": "text", "show": 1 },
  "aircrafttype": { "type": "text", "show": 1 },
  "loadrule": { "type": "text", "show": 1 },
  "windowmethod": { "type": "text", "show": 1 },
  "windowmin": { "type": "text", "show": 1 },
  "windowmax": { "type": "text", "show": 1 },
  "dow_long": { "type": "text", "show": 1 },
  "flightmin": { "type": "text", "show": 1 },
  "flightmax": { "type": "text", "show": 1 },
  "perishsectormin": { "type": "text", "show": 1 },
  "perishsectormax": { "type": "text", "show": 1 },
  "groundtimemin": { "type": "text", "show": 1 },
  "groundtimemax": { "type": "text", "show": 1 },
  "flightdurationmin": { "type": "text", "show": 1 },
  "flightdurationmax": { "type": "text", "show": 1 },
  "paxcalc": { "type": "text", "show": 1 },
  "paxdescription": { "type": "text", "show": 1 },
  "paxmin": { "type": "text", "show": 1 },
  "paxmax": { "type": "text", "show": 1 },
  "paxbufferquantity": { "type": "text", "show": 1 },
  "loadscale": { "type": "text", "show": 1 },
  "consumptionratio": { "type": "text", "show": 1 },
  "terminatedflag": { "type": "text", "show": 1 },
  "lastflightflag": { "type": "text", "show": 1 },
  "lastflightflagprev": { "type": "text", "show": 1 },
  "baseloadflag": { "type": "text", "show": 1 },
  "upliftflag": { "type": "text", "show": 1 },
  "startdate": { "type": "text", "show": 1 },
  "enddate": { "type": "text", "show": 1 },
  "servicetype": { "type": "text", "show": 1 },
  "budget": { "type": "text", "show": 1 },
  "mscflag": { "type": "text", "show": 1 }
}

export const MENU_CONDITION_DEFAULTS = {
  "condition_order": {
    "default": "",
    "css": "col-span-1",
    "name": "Condition Order"
  },
  "id": {
    "default": "",
    "css": "col-span-1",
    "name": "Condition ID"
  },
  "shortdescription": {
    "default": "",
    "css": "col-span-1",
    "name": "Description"
  },
  "airline": {
    "default": "ALL",
    "css": "col-span-1",
    "name": "Airline"
  },
  "budget": {
    "default": "",
    "css": "col-span-1",
    "name": "Budget"
  },
  "segmentkey": {
    "default": "ALL_SECTORS",
    "css": "col-span-3",
    "name": "Segment",
    "modal": {
      "open": false,
      "title": "Sectors",
      "slug": "E731BA672D663112F6DEB3C4459EC53C",
      "fld": "segmentkey",
      "template": "{departureport}-{arrivalport}",
      "filter_condition_dt": true,
      "params": {
        "orderBy": "departureport",
        "_fields": ["departureport", "arrivalport"]
      }
    }
  },
  "sector": {
    "default": "ALL-PORTS-ALL-PORTS",
    "css": "col-span-1",
    "name": "Sector",
    "flds": ["departureport", "arrivalport"]
  },
  "mscflag": {
    "default": "",
    "css": "col-span-1",
    "name": "Exclude MSC"
  },
  "aircrafttype": {
    "default": "ALL",
    "css": "col-span-1",
    "name": "Aircraft Type",
    "modal": {
      "open": false,
      "title": "Aircraft Types",
      "slug": "8D1556E8B50C79AF5F64F262347B8EF3",
      "fld": "Aircrafttypesegments",
      "exclude_values": ["ALL"],
      "template": "{manufacturer}: {aircrafttypekey}",
      "params": {
        "orderBy": "manufacturer,aircrafttypekey",
        "_fields": ["manufacturer", "aircrafttypekey"]
      }
    }
  },

  "flightrange": {
    "default": '0-9999',
    "css": "col-span-1",
    "name": "Flight Range",
    "flds": ["flightmin", "flightmax"]
  },
  "flightduration": {
    "default": "0 to 9999",
    "css": "col-span-1",
    "name": "Flt Duration",
    "flds": ["flightdurationmin", "flightdurationmax"]
  },
  // "flightdurationmax": {
  //   "default": 9999,
  //   "css": "col-span-1",
  //   "name": "Dur Max"
  // },
  "groundtime": {
    "default": "0 to 99999",
    "css": "col-span-1",
    "name": "Ground Time",
    "flds": ["groundtimemin", "groundtimemax"]
  },
  "windowrange": {
    "default": "STD 0 to 2359",
    "css": "col-span-2",
    "name": "Window Times",
    "flds": ["windowmethod", "windowmin", "windowmax"]
  },
  "lastflightflag": {
    "default": false,
    "css": "col-span-1",
    "name": "Last Flt"
  },
  "dow_long": {
    "default": "Mon-Tue-Wed-Thu-Fri-Sat-Sun",
    "css": "col-span-2",
    "name": "DOW"
  },
  "paxcalc": {
    "default": "",
    "css": "col-span-1",
    "name": "Pax Group"
  },
  "paxrange": {
    "default": "0 to 9999",
    "css": "col-span-1",
    "suffix": "PAX",
    "name": "Pax Range",
    "flds": ["paxmin", "paxmax"]
  },

  "paxbufferquantity": {
    "default": 0,
    "css": "col-span-1",
    "suffix": "PAX",
    "name": "Buffer"
  },
  "perishsectorrange": {
    "default": "0 to 9999",
    "css": "col-span-2",
    "suffix": "Sectors",
    "name": "Perish Range",
    "flds": ["perishsectormin", "perishsectormax"]
  },
  "loadscale": {
    "default": "Unknown",
    "css": "col-span-2",
    "name": "Load Qty Calc"
  },
  "loadrule": {
    "default": "ALL",
    "css": "col-span-2",
    "name": "Load Rule"
  },
  "daterange": {
    "default": "ALL",
    "css": "col-span-2",
    "name": "Date Range",
    "flds": ["startdate", "enddate"]
  },
  "servicetype": {
    "default": "ALL",
    "css": "col-span-3",
    "name": "ServiceType"
  },
  "servicetypegroupdescription": {
    "default": "ALL",
    "css": "col-span-3",
    "name": "Service Group"
  },
  "app": {
    "default": "Lastmile",
    "css": "col-span-2",
    "name": "App"
  }
}


export const GLP_CONDITION_DEFAULTS = {
  "condition_order": {
    "default": "",
    "css": "col-span-1",
    "name": "Condition Order"
  },
  "id": {
    "default": "",
    "css": "col-span-1",
    "name": "Condition ID"
  },
  "segmentkey": {
    "default": "ALL_SECTORS",
    "css": "col-span-3",
    "name": "Segment",
    "modal": {
      "open": false,
      "title": "Sectors",
      "slug": "E731BA672D663112F6DEB3C4459EC53C",
      "fld": "segmentkey",
      "template": "{departureport}-{arrivalport}",
      "params": {
        "orderBy": "departureport",
        "_fields": ["departureport", "arrivalport"]
      }
    }
  },
  "airline": {
    "default": "ALL",
    "css": "col-span-2",
    "name": "Airline"
  },
  "sector": {
    "default": "ALL-PORTS-ALL-PORTS",
    "css": "col-span-2",
    "name": "Sector"
  },
  "servicetype": {
    "default": "ALL",
    "css": "col-span-1",
    "name": "Service Type"
  },
  "flightrange": {
    "default": '0-9999',
    "css": "col-span-1",
    "name": "Flight Range"
  },
  "dow_long": {
    "default": "Mon-Tue-Wed-Thu-Fri-Sat-Sun",
    "css": "col-span-2",
    "name": "DOW"
  },
  "paxclass": {
    "default": "ALL",
    "css": "col-span-1",
    "name": "Pax Class"
  },
  "daterange": {
    "default": "ALL",
    "css": "col-span-2",
    "name": "Date Range",
    "flds": ["startdate", "enddate"]
  },
  "paxrange": {
    "default": "0 to 9999",
    "css": "col-span-1",
    "suffix": "PAX",
    "name": "Pax Range",
    "flds": ["paxmin", "paxmax"]
  },
  "windowrange": {
    "default": "0 to 2359",
    "css": "col-span-2",
    "name": "Window Times",
    "flds": ["windowmin", "windowmax"]
  },
}

export const GRID_DEFAULT = {
  "pivot_arr": false,
  "col": "",
  "is_nullable": "NO",
  "select": true,
  "formular": null,
  "values": false,
  "available": false,
  "icon": "",
  "align": "text-left",
  "type": "text",
  "tablename": "",
  "direction": true,
  "group": "Unknown",
  "order": 1,
  "column_class": null,
  "format": null,
  "orderby": false,
  "filters": false,
  "groupby": false,
  "css_td_class": null,
  "directive": null,
  "is_summable": 0,
  "hide": false,
  "field": "",
  "formtype": "input",
  "decimals": 0,
  "name": "",
  "width": "120",
  "cell_style": "",
  "style": "info",
  "v_component": null,
  "v_cls": "",
}

export const FORM_DEFAULT = {
  "_edit": false,
  "type": "text",
  "hide": false,
  "field": "",
  "popover": null,
  "formtype": "text",
  "locked_value": false,
  "name": "",
  "disabled": false,
  "style": "info",
  "placeholder": "",
  "value": null,
  "_editCombo": false,
  "v_col_width": "col-span-12"
}

export const CellTypes = {
  Default: {
    "type": "text",
    "align": "text-left",
    "width": 120
  },
  CellTypeFormula: {
    "align": "text-right",
    "width": 120
  },
  CellTypeText: {
    "align": "text-left",
    "width": 120
  },
  CellTypeDate: {
    "format": "YYYY-MM-DD",
    "align": "text-center",
    "width": 90
  },
  CellTypeMoney: {
    "align": "text-right",
    "decimal": 2,
  },
  CellTypeNumber: {
    "align": "text-right",
    "decimal": 2,
  },
  CellTypeBit: {
    "align": "text-center",
  }
}

export const RowColors = {
  success: 'bg-green-50 text-green-800 hover:bg-green-400',
  danger: 'bg-red-50 text-red-800 hover:bg-red-200',
  warning: 'bg-yellow-50 text-yellow-800 hover:bg-yellow-400',
  gray: 'bg-gray-100 text-gray-800 hover:bg-gray-400',
  info: 'bg-blue-50 text-blue-800 hover:bg-blue-400',
  default: 'bg-gray-100 text-gray-800 hover:bg-gray-400',
  'opacity-50': 'opacity-50',
}


export const IconColors = {
  success: 'bg-green-500 text-white',
  danger: 'bg-red-600 text-white',
  warning: 'bg-yellow-600 text-white',
  gray: 'bg-gray-600 text-white',
  info: 'bg-primary-500 text-white',
  default: 'bg-gray-600 text-white'
}

export const MOVEMENT = {
  port_focus: 'UNK',
  caterer_focus: 'Unallocated',
  port_target: "UNK",
  caterer_target: "UNK",
  port_source: 'UNK',
  caterer_source: 'Unallocated',
  masterlistkey: "UNK",
  emergencyflag: false,
  comat_flag: false,
  directdeliveryflag: false,
  expected_delivery_date: "9999-12-31",
  movementaction_id: 0,
  direction: "UNK",
  end_of_life: 0,
  device_submit_ts: null,
  po_airline: null,
  required_date: "2000-12-31",
  parent_reference: "UNK",
  tp_reference: 'UNK',
  auth0_id: "UNK",
  quantity: 0,
}

export const STATUS_COLORS = {
  "UNK": "default",
  "Draft": "warning",
  "Pending": "warning",
  "Expired": "danger",
  "Disputed": "danger",
  "Declined": "danger",
  "Allocated": "success",
  "Authorised": "success",
  "Auth (FUTURE)": "success",
  "Locked": "dark",
  "W&B Accepted": "success",
  "W&B Declined": "danger",
  "Caterer Review": "info",
  "Open": "danger",
  "Finalised": "success",
  "Closed": "success",
  "Prior Month": "default",
  "Reporting Only": "default",
  "Investigate": "info",
  "Live": "success",
  "Declined": "success",
  "Enforced-Expired": "info",
  "Port Accept": "success",
  "Port Dispute": "danger",
  "Overturned": "warning",
  "Authorised Caterer": "success",
  "Caterer Declined": "danger",
  "Complete": "success",
  "Running": "info",
  "HO Excluded": "default",
  "Published": "success",
  "Archived": "default",
  "W&B Review": "info",
  "Caterer Accepted": "success",
  "Enforced": "info",
}