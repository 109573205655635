import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Healthcheck from "../views/Healthcheck.vue";
import ReloadOnRouteChange from "./ReloadOnRouteChange.vue";
import { routeGuard, resetClient } from "@/auth";


// routes
const routes = [
  {
    path: "/",
    name: "Home",
    meta: { public: true },
    beforeEnter: resetClient,
    component: Home,
  },
  {
    path: "/hc/:apikey", // health check
    name: "health",
    meta: { public: true },
    beforeEnter: resetClient,
    component: Healthcheck,
  },
  {
    path: "/logout", // logout
    name: "logout",
    meta: { public: true },
    component: () => import("../views/Logout.vue"),
  },
  {
    path: "/:client/change_password",
    name: "change_pwd",
    beforeEnter: routeGuard,
    component: () => import("../views/ChangePwd.vue"),
  },
  {
    path: "/:client/unauthorised",
    name: "Unauthorised",
    meta: { public: true },
    component: () => import("../views/Unauthorised.vue"),
  },
  {
    path: "/:client/welcome",
    name: "welcome",
    beforeEnter: routeGuard,
    component: () => import("../views/Welcome.vue"),
  },
  {
    path: "/:client/grid/:slug",
    name: "GridOnly",
    props: true,
    beforeEnter: routeGuard,
    component: () => import("../views/GridOnly.vue"),
  },
  {
    path: "/:client/help",
    name: "help",
    beforeEnter: routeGuard,
    component: () => import("../views/Help.vue"),
  },
  {
    path: "/:client/backup",
    name: "backup",
    beforeEnter: routeGuard,
    component: () => import("@/components/Mobile/MobileBackup.vue"),
  },
  {
    path: "/:client/mobile/:id",
    name: "mobilehome",
    beforeEnter: routeGuard,
    component: () => import("@/components/Mobile/MobileHome.vue"),
  },

  {
    path: "/:client/mobile/:id/:module",
    name: "mobilelastmile",
    props: true,
    beforeEnter: routeGuard,
    component: () => import("@/components/Mobile/MobileLastmile.vue"),
  },

  {
    path: "/:client/mobile/crew/:id/:module",
    name: "mobilecrewapp",
    props: true,
    beforeEnter: routeGuard,
    component: () => import("@/components/Mobile/CrewApp/MobileCrewHome.vue"),
  },
  {
    path: "/:client/mobile/crew/:id/:module/:flightkey",
    name: "mobileCrewFlightDetail",
    props: true,
    component: () => import("@/components/Mobile/CrewApp/MobileCrewFlightDetail.vue")
  },
  {
    path: "/:client/mobile/suppliers",
    name: "mobilehomesuppliers",
    beforeEnter: routeGuard,
    component: () => import("@/components/Mobile/MobileHomeSuppliers.vue"),
  },
  {
    path: "/callback",
    name: "Callback",
    component: () => import("../views/Callback.vue"),
  },

  {
    path: "/:client/:module/page/:slug",
    name: "Page",
    props: true,
    beforeEnter: routeGuard,
    component: () => import("../views/Page.vue"),
  },

  {
    path: "/",
    name: "Supplier",
    component: ReloadOnRouteChange,
    children: [
      {
        path: "/:client/:module/supplier/:id/:slug",
        name: "supplier.go",
        props: true,
        beforeEnter: routeGuard,
        component: () => import("../views/Page.vue"),
      },
    ],
  },
  {
    path: "/:client/mobile/:id/:module/:slug",
    name: "mobilepage",
    props: true,
    beforeEnter: routeGuard,
    component: () => import("@/components/Mobile/MobilePage.vue"),
  },
  {
    path: "/:client/mobile/:id/health",
    name: "mobilehealthpage",
    props: true,
    beforeEnter: routeGuard,
    component: () => import("@/components/Mobile/Health/MobileHealthPage.vue"),
  },
  {
    path: "/:client/profile/:auth0_id",
    name: "profile",
    props: true,
    beforeEnter: routeGuard,
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/:client/roles/:slug/:role_id",
    name: "role",
    props: true,
    beforeEnter: routeGuard,
    component: () => import("../views/Roles.vue"),
  },
  {
    path: "/:client/admin/:slug", // admin
    name: "admin",
    props: true,
    beforeEnter: routeGuard,
    component: () => import("../views/Admin.vue"),
  },
  {
    path: "/:pathMatch(.*)*", // default route
    beforeEnter: routeGuard,
    component: () => import("../views/Welcome.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL), // createWebHashHistory
  routes,
});

export default router;
