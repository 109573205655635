<template>
  <TgSlidePanel :open="showFormPanel" :on-close="closeFormPanel" side="left" width="max-w-5xl" :overlay="true" z="z-40">
    <template #title>
      FR-{{ item.id }}
    </template>
    <template #subtitle>
      Flight report form
    </template>
    <template #content>
      <form-panel ref="FormPanel" :slug="slug" :tab="tab" :selected="item" @formerror="formHasError"
        @formsuccess="receiveFormData" />
    </template>
    <template #footer>
      <TgSubmit :disabled="submitting" @click="submitForm">
        Update
      </TgSubmit>
    </template>
  </TgSlidePanel>

  <div v-if="item" class="el_1_f08d9a633a0e7d7808a0f51038aa8648 bg-gray-100 rounded-md">
    <div class="el_2_f08d9a633a0e7d7808a0f51038aa8648 p-3 uppercase rounded-t-md"
      :class="status_colors[item.status_colors]">
      <div class="el_3_f08d9a633a0e7d7808a0f51038aa8648 flex items-center text-white">
        <div class="el_4_f08d9a633a0e7d7808a0f51038aa8648 flex-1 text-lg">
          Flight report #{{ item.id }} -
          <span class="el_5_f08d9a633a0e7d7808a0f51038aa8648 text-base">({{ item.hoursremaining }} Hours Remain)</span>
        </div>
        <div class="el_6_f08d9a633a0e7d7808a0f51038aa8648 flex-shrink-0 text-right">
          <TgBadge v-if="tab.access == 'Full'" color="dark" class="cursor-pointer" @click="openForm()">
            Edit
          </TgBadge>
        </div>
      </div>
    </div>
    <div class="el_7_f08d9a633a0e7d7808a0f51038aa8648 flex flex-col px-4 py-4 space-y-2">
      <div class="el_8_f08d9a633a0e7d7808a0f51038aa8648 flex-1 space-y-2">
        <div class="el_9_f08d9a633a0e7d7808a0f51038aa8648 flex items-center">
          <!-- <div class="el_10_f08d9a633a0e7d7808a0f51038aa8648 w-1/4">
            <div class="el_11_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              Section
            </div>
            <div class="el_12_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 whitespace-normal">
              {{ item.section }}
            </div>
          </div> -->
          <div class="el_13_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_14_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              category
            </div>
            <div class="el_15_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.category }}
            </div>
          </div>
          <div class="el_16_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_17_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              subcategory
            </div>
            <div class="el_18_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.subcategory }}
            </div>
          </div>
          <div class="el_19_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_20_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              cabin class
            </div>
            <div class="el_21_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.cabinclass }}
            </div>
          </div>
        </div>
        <div class="el_22_f08d9a633a0e7d7808a0f51038aa8648 flex items-center">
          <div class="el_23_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_24_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              Derived Caterer
            </div>
            <div class="el_25_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.caterer }}
            </div>
          </div>
          <div class="el_26_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_27_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              Derived Port
            </div>
            <div class="el_28_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.departureport }}
            </div>
          </div>
          <div class="el_29_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_30_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              Service Port
            </div>
            <div class="el_31_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.targetport }}
            </div>
          </div>
        </div>
        <div class="el_35_f08d9a633a0e7d7808a0f51038aa8648 flex items-center">
          <div class="el_32_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_33_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              Hours Remaining
            </div>
            <div class="el_34_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.hoursremaining }}
            </div>
          </div>
          <div class="el_36_f08d9a633a0e7d7808a0f51038aa8648 w-1/3">
            <div class="el_37_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              OTP Minutes
            </div>
            <div class="el_38_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.minutes || "N/A" }}
            </div>
          </div>

          <!-- <div class="el_39_f08d9a633a0e7d7808a0f51038aa8648 w-2/4">
            <div class="el_40_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
              MSC Flight
            </div>
            <div class="el_41_f08d9a633a0e7d7808a0f51038aa8648 text-gray-600 truncate">
              {{ item.mscflag || "N/A" }}
            </div>
          </div> -->
        </div>
      </div>
      <div class="el_42_f08d9a633a0e7d7808a0f51038aa8648 flex-col space-y-3">
        <div class="el_43_f08d9a633a0e7d7808a0f51038aa8648 flex-1">
          <div class="el_44_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
            Crew Comment
          </div>
          <div
            class="el_45_f08d9a633a0e7d7808a0f51038aa8648 text-base text-gray-600 whitespace-pre-line bg-gray-200 p-2 mt-2 rounded-md">
            {{ item.comments }}
          </div>
        </div>
        <div class="el_46_f08d9a633a0e7d7808a0f51038aa8648 flex-1">
          <div class="el_47_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase flex justify-between">
            <div>Caterer Response</div>
            <TgBadge v-if="item.status_caterer_id == 2026" :color="'danger'">
              Declined
            </TgBadge>
            <TgBadge v-if="item.status_caterer_id == 2025" :color="'success'">
              Accepted
            </TgBadge>
          </div>
          <div
            class="el_48_f08d9a633a0e7d7808a0f51038aa8648 text-base text-gray-600 whitespace-normal bg-white p-2 mt-2 rounded-md">
            {{ item.catererresponse }}
            <div v-if="item.caterer_response_by" class="text-sm text-gray-400 pt-2">
              By: {{ item.caterer_response_by }} - {{ item.catererresponsets }}
            </div>
          </div>
        </div>
        <div class="el_49_f08d9a633a0e7d7808a0f51038aa8648 flex-1">
          <div class="el_50_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase flex justify-between">
            <div>Airline Response</div>
            <TgBadge v-if="item.status_airline_id == 2026" :color="'danger'">
              Disagree
            </TgBadge>
            <TgBadge v-if="item.status_airline_id == 2025" :color="'success'">
              Agree
            </TgBadge>
          </div>
          <div
            class="el_51_f08d9a633a0e7d7808a0f51038aa8648 text-base text-gray-600 whitespace-normal bg-white p-2 mt-2 rounded-md">
            {{ item.airlineresponse }}
            <div v-if="item.airline_response_by" class="text-sm text-gray-400 pt-2">
              By: {{ item.airline_response_by }} - {{ item.airlineresponsets }}
            </div>
          </div>
        </div>
        <div v-if="item.imagename" class="el_52_f08d9a633a0e7d7808a0f51038aa8648 flex-1">
          <div class="el_53_f08d9a633a0e7d7808a0f51038aa8648 mb-2 text-gray-400 uppercase">
            Photo
          </div>
          <tg-image :path="item.imagename" :thumb="true" class="shadow-md" />
        </div>
        <div v-if="attachments.length > 0" class="text-gray-400 uppercase pt-2">
          Attachments
        </div>
        <div class="flex space-x-2">
          <div v-for="(attachment, file_idx) in attachments" :key="file_idx" class="flex-shrink-0 px-3">
            <div v-if="attachment.resource_type == 'image'" class="text-center group flex flex-col">
              <tg-image :path="attachment.public_id" :thumb="true" class="shadow-md" />
              <div class="text-center text-sm cursor-pointer">
                {{ attachment.filename }}
              </div>
              <span class="text-red-400 text-xs invisible hover:underline cursor-pointer group-hover:visible"
                @click="remove_attachement(file_idx)">Remove</span>
            </div>
            <div v-else class="text-center group flex flex-col">
              <div class="h-16">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                  stroke="currentColor" class="w-16 h-16 mx-auto">
                  <path stroke-linecap="round" stroke-linejoin="round"
                    d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                </svg>
              </div>
              <a class="text-center text-sm cursor-pointer" :href="attachment.secure_url" target="_blank">{{
                attachment.filename }}</a>
              <span class="text-red-400 text-xs invisible hover:underline cursor-pointer group-hover:visible"
                @click="remove_attachement(file_idx)">Remove</span>
            </div>
          </div>
        </div>
        <div v-if="item._canedit == 1">
          <TgCloudinaryUploader :folder="`performance/${item.id}/${guid}`" @upload:success="afterUpload" />
        </div>
        <div class="el_54_f08d9a633a0e7d7808a0f51038aa8648 flex-1 mt-3 text-center">
          <div class="el_55_f08d9a633a0e7d7808a0f51038aa8648 text-gray-400 uppercase">
            Overall Status
          </div>
          <div class="el_56_f08d9a633a0e7d7808a0f51038aa8648 text-2xl text-gray-600 whitespace-normal">
            <TgBadge :color="item.status_css">
              {{ item.status }}
            </TgBadge>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TgSlidePanel from "@/components/Common/TgSlidePanel.vue";
import { API } from "@/api";

import { UTILS } from "@/utils";
import { STATUS_COLORS } from "@/default";
import TgBadge from "@/components/Common/TgBadge";
import TgSubmit from "@/components/Common/TgSubmit";
import TgImage from "@/components/Common/TgImage";
import TgCloudinaryUploader from "@/components/Common/TgCloudinaryUploader.vue";

import _has from "lodash/has";
import _get from "lodash/get";
import _each from "lodash/each";
import FormPanel from "@/components/Grids/FormPanel.vue";

export default {
  components: {
    TgSlidePanel,
    TgSubmit,
    FormPanel,
    TgBadge,
    TgImage,
    TgCloudinaryUploader,
  },
  props: {
    item: Object,
    config: Object,
    tab: Object,
    refreshlist: Function,
  },
  emits: ["on:update", "on:refresh"],
  data() {
    return {
      emitter: API.get_emitter(),
      attachments: JSON.parse(_get(this.item, "attachments", "[]")),
      colors: STATUS_COLORS,
      guid: UTILS.guid(),
      categories: null,
      open: false,
      submitting: false,
      showFormPanel: false,
      slug: null,
      status_colors: {
        red: "bg-red-400 text-white",
        yellow: "bg-yellow-400 text-white",
        blue: "bg-primary-400 text-white",
        green: "bg-green-400 text-white",
        gray: "bg-gray-400 text-white",
      },
    };
  },

  methods: {
    async remove_attachement(idx) {
      this.attachments.splice(idx, 1);
      await API.update("84D444B71CE4441CADED9890820B3805", this.item.id, {
        attachments: JSON.stringify(this.attachments),
      });
      this.$notify({
        group: "toast",
        type: "success",
        title: "Success",
        text: "Report saved.",
      });
    },
    async afterUpload(files) {
      let attachments = JSON.parse(JSON.stringify(this.attachments));

      _each(files, (file) => {
        let public_id = file.public_id.split("/");
        file.filename = public_id[4];
        attachments.push(file);
      });

      await API.update("84D444B71CE4441CADED9890820B3805", this.item.id, {
        attachments: JSON.stringify(attachments),
      });
      this.$emit("on:refresh");
    },
    openForm() {
      let slug = this.config.formslug;

      if (_has(this.config, "supplier")) {
        if (this.item._canedit == 0) {
          this.$notify({
            group: "toast",
            type: "danger",
            title: "Edit Denied",
            text: "This report has been closed. No changes can be made.",
          });
          return;
        }
      }
      this.slug = slug;
      this.openFormPanel();
    },
    openForm_old() {
      let slug = "6464A3BE24474C98B28DB96AF4D9E9BD"; // HO form

      if (_has(this.config, "supplier")) {
        if (this.item.status == "Closed IFS") {
          this.$notify({
            group: "toast",
            type: "danger",
            title: "Edit Denied",
            text: "This report has been closed. No changes can be made.",
          });
          return;
        }

        slug = "059C953AD93D456C9E1823FCFDB65995"; // supplier form - VA
        if (this.$auth.client.value.key == "ib") {
          if (this.item._canedit == 0) {
            this.$notify({
              group: "toast",
              type: "danger",
              title: "Edit Denied",
              text: "This report has been closed. No changes can be made.",
            });
            return;
          }

          slug = "CB33B3605C05442087833B872D73186A"; // supplier form - IB
        }
      } else {
        if (this.$auth.client.value.key == "ib") {
          slug = "7C2F568ADF5344B0885FCA0C16FD4221"; // HO form
        }
      }
      this.slug = slug;
      this.openFormPanel();
    },
    openFormPanel() {
      this.showFormPanel = true;
    },
    formHasError() {
      this.submitting = false;
    },
    async receiveFormData(data) {
      this.submitting = false;
      if (this.$auth.client.value.key == "va") {
        await API.proc({
          proc: "SLAp_01_RefreshSLARunner_byID",
          params: [data.row.id],
        });
      }
      this.emitter.emit("crew-report-updated");
      this.refreshlist();
      this.closeFormPanel();
    },
    submitForm() {
      this.submitting = true;
      this.$refs.FormPanel.submit();
    },
    closeFormPanel() {
      this.showFormPanel = false;
    },
  },
};
</script>
<style></style>
;
