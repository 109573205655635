<template>
  <tg-modal :open="true" size="w-11/12" class="z-50" @modal-close="done">
    <template #header class="justify-center items-center">
      Fields Configuration
    </template>

    <template #content>
      <div class="el_1_9e3968503c902d2f1595fc8b75c7ef28 overflow-x-auto">
        <table class="min-w-full divide-y divide-gray-200" :style="{ width: config_container_width + 'px' }">
          <thead>
            <tr>
              <td class="absolute mb-1 bg-gray-300" style="min-width: 110px">
                Fields
              </td>
              <td style="min-width: 115px">
                &nbsp;
              </td>
              <th v-for="(item, idx) in mutatedselect" v-show="!item.hide" :key="idx"
                class="bg-gray-300 border-b border-gray-400">
                <input v-model="item.field" type="text"
                  class="block py-1 w-full text-sm text-white bg-gray-400 rounded-md border-gray-200 focus:outline-none">
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="col in config" :key="col.field" class="hover:bg-primary-400 group">
              <td
                class="absolute h-full bg-gray-200 border-r border-gray-400 group-hover:bg-primary-400 group-hover:text-white"
                style="min-width: 110px">
                {{ col.name }}
              </td>
              <td>&nbsp;</td>
              <td v-for="item in mutatedselect" v-show="!item.hide" :key="item.field"
                :style="{ minWidth: calcWidth(item) }">
                <select v-if="col.field == 'type'" v-model="item.type"
                  class="block py-1 pr-10 pl-3 w-full text-sm rounded-md border-gray-200 focus:outline-none">
                  <option v-for="i in [
                    'flag',
                    'bit',
                    'toggle',
                    'url',
                    'docu_store_link',
                    'input',
                    'textarea',
                    'imageupload',
                    'comments',
                    'flowBtn',
                    'link',
                    'image',
                    'base64Image',
                    'html',
                    'date',
                    'datetime',
                    'directive',
                    'badge',
                    'signoff_button',
                    'button',
                    'button-count',
                    'money',
                    'number',
                    'text',
                    'formulae',
                    'file',
                    'template',
                    'switch',
                    'status',
                  ]" :key="i" :value="i">
                    {{ i }}
                  </option>
                </select>
                <select v-else-if="col.field == 'grouper_type_override'" v-model="item.grouper_type_override"
                  class="block py-1 pr-10 pl-3 w-full text-sm rounded-md border-gray-200 focus:outline-none">
                  <option v-for="i in ['money', 'number', 'formulae', 'text']" :key="i" :value="i">
                    {{ i }}
                  </option>
                </select>
                <select v-else-if="col.field == 'align'" v-model="item.align"
                  class="block py-1 pr-10 pl-3 w-full text-sm rounded-md border-gray-200 focus:outline-none">
                  <option v-for="i in [
                    { value: 'text-left', label: 'L' },
                    { value: 'text-center', label: 'C' },
                    { value: 'text-right', label: 'R' },
                  ]" :key="i.align" :value="i.value">
                    {{ i.label }}
                  </option>
                </select>
                <select v-else-if="col.field == 'hideZeros'" v-model="item.hideZeros"
                  class="block py-1 pr-10 pl-3 w-full text-sm rounded-md border-gray-200 focus:outline-none">
                  <option v-for="i in [
                    { value: null, label: '' },
                    { value: 0, label: 'Show' },
                    { value: 1, label: 'Hide' },
                  ]" :key="i.value" :value="i.value">
                    {{ i.label }}
                  </option>
                </select>
                <textarea v-else-if="col.field == 'popover'" v-model="item.popover" type="text"
                  class="block py-1 w-full h-28 text-sm rounded-md border-gray-200 focus:outline-none" />
                <textarea v-else-if="col.field == 'group_popover'" v-model="item.group_popover" type="text"
                  class="block py-1 w-full h-28 text-sm rounded-md border-gray-200 focus:outline-none" />
                <input v-else-if="col.type == 'checkbox'" v-model="item[col.field]" type="checkbox"
                  class="block py-1 text-sm text-center">
                <input v-else v-model="item[col.field]" type="text" :xclass="[
                  col.field == 'v_css' ? item.v_css : '',
                  col.field == 'col_spanner_css' ? item.col_spanner_css : '',
                ]" class="block py-1 w-full text-sm rounded-md border-gray-200 focus:outline-none">
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>

    <template #footer>
      <tg-btn :click="autoconfigure" color="primary" class="ml-3">
        Auto Configure
      </tg-btn>
      <tg-btn :click="done" color="success" class="ml-3">
        Done
      </tg-btn>
    </template>
  </tg-modal>
</template>

<script>
import TgModal from "@/components/Common/TgModal.vue";
import TgBtn from "@/components/Common/TgBtn.vue";
import { copyText } from "vue3-clipboard";
import { API } from "@/api";
import _each from "lodash/each";
import _map from "lodash/map";
import _get from "lodash/get";

import { UTILS } from "@/utils";

export default {
  name: "FieldsConfigModal",
  components: {
    TgModal,
    TgBtn,
  },
  props: {
    table: String,
    select: Array,
    slug: String,
    widthtype: String,
  },
  emits: ["update:select", "modal-close"],
  data() {
    return {
      data: null,
      config_container_width: 1000,
      config: [
        { field: "name", name: "Name" },
        { field: "type", name: "Type" },
        { field: "type_override", name: "Type Override" },
        { field: "grouper_type_override", name: "Group Type" },
        { field: "width", name: "Width" },
        { field: "align", name: "Align" },
        { field: "format", name: "Format" },
        { field: "col_spanner", name: "Group" },
        { field: "col_spanner_amt", name: "Group #" },
        // { field: "col_spanner_css", name: "Group CSS" },
        { field: "linkText", name: "Link Text" },
        { field: "v_css", name: "CSS" },
        { field: "v_component", name: "Component" },
        { field: "v_component_params", name: "Comp Params" },
        { field: "decimals", name: "Decimals" },
        { field: "v_template", name: "HTML Template" },
        { field: "subfield", name: "Sub Field" },
        { field: "subfield_css", name: "Sub Field CSS" },
        { field: "badge_colors", name: "Badge Colors" },
        { field: "v_media_css", name: "Media CSS" },
        { field: "v_cell_span_css", name: "Span CSS" },
        { field: "v_cell_data_css", name: "Cell Data CSS" },
        { field: "column_span", name: "Col Span", type: "checkbox" },
        { field: "prefix", name: "Prefix" },
        { field: "suffix", name: "Suffix" },
        { field: "client_hide", name: "Hide for Client" },
        { field: "hideZeros", name: "Blank Zeros" },
        { field: "force_width", name: "Force Width", type: "checkbox" },
        { field: "formulae", name: "Formulae" },
        { field: "popover", name: "Field Help" },
        { field: "group_popover", name: "Group Help" },
      ],
      mutatedselect: JSON.parse(JSON.stringify(this.select)),
      loading: false,
    };
  },
  computed: {
    calcWidth() {
      return (item) => {
        let len = item.field.length * 6 + 15;
        if (len < 120) len = 120;
        return len + "px";
      };
    },
  },
  methods: {
    copyCss(color, num) {
      const text = `bg-${color}-${num}`;
      copyText(text, undefined, (error, event) => {
        if (error) {
          alert("Can not copy");
          console.log(error);
        } else {
          alert("Copied");
          console.log(event);
        }
      });
    },
    done() {
      this.$emit("update:select", this.mutatedselect);
      this.$emit("modal-close");
    },
    async autoconfigure() {
      let resp = await API.raw(`schema/${this.slug}?tbl=${this.table}`, {}).catch((e) => {
        console.log(e);
      });

      if (resp) {
        let max_len = resp.data.max_lens[0];
        let schema_fields = resp.data.data;

        let widthtype = this.widthtype;

        if (!widthtype) widthtype = "px";

        let visable_fields = 0;

        _each(this.mutatedselect, (i) => {
          if (!i.hide) visable_fields++;
        });

        this.config_container_width = visable_fields * 100;

        this.mutatedselect = _map(this.mutatedselect, (i) => {
          if (this.widthtype == "px") {
            let max = _get(max_len, i.field, false);

            if (i.name.length > max) max = i.name.length;

            if (max !== false) {
              i.width = max * 6 + 15;
            }

            if (i.width < 50) i.width = 50;
          } else if (this.widthtype == "%") {
            i.width = 100 / visable_fields;
          }

          let schema = _get(schema_fields, i.field, false);

          if (schema) {
            if (["date", "datetime", "bit"].indexOf(schema.data_type) != -1) {
              i.align = "text-center";
            }
            if (
              ["bigint", "int", "float", "tinyint", "numeric", "smallint", "decimal"].indexOf(
                schema.data_type
              ) != -1
            ) {
              i.align = "text-right";
            }
            if (["date"].indexOf(schema.data_type) != -1) {
              i.format = "YYYY-MM-DD";
            }
          }

          return i;
        });
      }
    },
  },
};
</script>

<style></style>
