<template>
  <div class="flex flex-col items-center">
    <!-- Field Label -->
    <label :for="item.field" class="block relative text-sm font-medium uppercase text-primary-800 mb-4">
      <span v-if="item.required" class="inline mr-1 text-sm text-red-400">*</span>
      {{ item.name }}
    </label>

    <!-- Image Display -->
    <div class="w-full max-w-md bg-white rounded-md shadow-sm p-4 mb-4 flex flex-col items-center">

      <!-- Upload Progress -->
      <div v-if="sending" class="w-full max-w-md mt-4 text-center">
        <TgLoader />
        <div class="mt-2 text-gray-600">
          Uploading {{ complete }} of {{ total }} files...
        </div>
      </div>
      <template v-else>
        <template v-if="props.modelValue">
          <template v-if="!imgError">
            <img class="mx-auto" :src="comp" @error="handleImgError" />
          </template>
          <template v-else>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-6 h-6">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
            </svg>
          </template>
          <div class="mt-2 flex space-x-4">
            <button type="button" class="text-sm text-red-400" @click="del">Remove</button>
            <button type="button" class="text-sm text-primary-600" @click="triggerFilePickerAndReset">Replace</button>
          </div>
        </template>
        <template v-else>

          <div class="flex flex-col items-center cursor-pointer" @click="triggerFilePickerAndReset">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
              stroke="currentColor" class="w-12 h-12 text-gray-400">
              <path stroke-linecap="round" stroke-linejoin="round"
                d="m2.25 15.75 5.159-5.159a2.25 2.25 0 0 1 3.182 0l5.159 5.159m-1.5-1.5 1.409-1.409a2.25 2.25 0 0 1 3.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 0 0 1.5-1.5V6a1.5 1.5 0 0 0-1.5-1.5H3.75A1.5 1.5 0 0 0 2.25 6v12a1.5 1.5 0 0 0 1.5 1.5Zm10.5-11.25h.008v.008h-.008V8.25Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
            </svg>
            <div class="mt-2 text-gray-600">Click to add an image</div>
          </div>
        </template>
      </template>
    </div>

    <!-- Hidden File Input for Uploader -->
    <input id="file-upload" ref="fileRef" type="file" multiple accept=".pdf,.jpg,.jpeg,.png" @change="onChange"
      class="hidden" />




  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
import { useRoute } from 'vue-router'
import TgLoader from '@/components/Common/TgLoader.vue'
import _head from 'lodash/head'

const props = defineProps({
  item: Object,
  filename: String,
  folder: String,
  slug: String,
  error: String,
  modelValue: {
    type: [String, Number, Boolean],
    default: '',
  }
})
const emit = defineEmits(['update:modelValue', 'update:error'])

const imgError = ref(false)

// Computed image URL for display
const comp = computed(() => props.modelValue)

// ----- Uploader Logic -----
const route = useRoute()
const clientKey = computed(() => route.params.client)

const fileRef = ref(null)
const previewFiles = ref([])
const complete = ref(0)
const total = ref(0)
const sending = ref(false)

function onChange(event) {
  const files = event.target.files
  if (files.length) {
    const fileArray = Array.from(files)
    previewFiles.value = fileArray
    processFiles(fileArray)
  }
}

function processFiles(files) {
  total.value = files.length
  complete.value = 0
  sending.value = true

  const jobs = files.map(file => upload(file))
  Promise.all(jobs)
    .then(resp => {
      sending.value = false
      upload_success(resp)
      // Clear file input and preview files after successful upload
      if (fileRef.value) fileRef.value.value = ''
      previewFiles.value = []
    })
    .catch(err => {
      console.error('Error during upload:', err)
      sending.value = false
    })
}

async function upload(file) {
  const CLOUDINARY_URL = "https://api.cloudinary.com/v1_1/tplive/upload"
  const CLOUDINARY_UPLOAD_PRESET = "vue_wvky6qwr"

  const formData = new FormData()
  formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET)
  formData.append("folder", `${clientKey.value}/${props.folder}`)
  formData.append("file", file)
  // Generate a unique public_id by appending the current timestamp
  const uniquePublicId = props.filename
    ? `${props.filename}-${Date.now()}`
    : file.name.split(".")[0] + '-' + Date.now()
  formData.append("public_id", uniquePublicId)

  try {
    const response = await fetch(CLOUDINARY_URL, {
      method: "POST",
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      },
      body: formData,
    })
    const data = await response.json()
    if (response.ok) {
      complete.value++
      return data
    } else {
      const errorMsg = data.error?.message || "Upload failed"
      throw new Error(errorMsg)
    }
  } catch (error) {
    console.error("Upload error:", error)
    throw error
  }
}

function upload_success(imageArray) {
  const uploaded = _head(imageArray)
  const secure_url = uploaded.secure_url
  const prefix = 'https://res.cloudinary.com/tplive/image/upload/'
  // Preserve the Cloudinary version in the URL
  let url = secure_url.startsWith(prefix)
    ? secure_url.replace(prefix, '')
    : secure_url
  // Reset any image error state
  imgError.value = false
  console.log('Uploaded:', secure_url)
  emit('update:modelValue', secure_url)
}

function del() {
  emit('update:modelValue', '')
}

function handleImgError() {
  imgError.value = true
}

function triggerFilePicker() {
  if (fileRef.value) {
    fileRef.value.click()
  }
}

// Reset error state when user clicks Replace or to add an image
function triggerFilePickerAndReset() {
  imgError.value = false
  triggerFilePicker()
}
</script>

<style scoped>
.uploader-container {
  max-width: 500px;
  margin: 0 auto;
}

.file-upload-label {
  font-size: 1rem;
  color: #4a5568;
}

.preview-area {
  font-size: 0.9rem;
}

.preview-item {
  background-color: #f7fafc;
}
</style>